body .container,
body .MuiInputBase-root ,
body .form-control,
body .custom-select,
body .btn,
body .MuiTreeItem-label
  {
  font-size:0.9rem;
}


.App {
  text-align: left;
}
#root nav {
  display:flex;
  justify-content: space-around;
  align-items: center;
}
.nav-links {
  width: 50%;
  display: flex;
  list-style:none ;
}
.nav-links li {
  margin-left: 10px;
}

#nav-top {
  -webkit-box-pack: justify;
  -webkit-box-align: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #aaa;
  height: 60px;
  margin-bottom: 20px;
  background-color:black;
  color: white;
  
}
.container .nav-left {
    padding: 0;
}
.container .nav-left  a:link,
.container .nav-left  a:visited {
  color: black;
  font-weight: bold;
  text-decoration: none;
}

.logout {
  float:right;
  text-align:right;
  padding-right: 20px;
}
.impersonate-info {
  padding-right: 20px;
  float:left; 
}
#nav-top a:link, #nav-top a:visited{
  text-decoration: none;
  color: white;
}
body .container {
  max-width: 100%;
}
.zone-title {
  text-align:right;
  padding-right:0;
}
.login {
  margin-left: 40%;
  margin-right:40%;
  max-width: 500px;
  min-width: 500px;
  margin-top:100px;
}
.logout {
  cursor: pointer;
}
.table .edit-button {
  color: #aaa;
}
.table .edit-button:hover {
  color: #444;
}
.table .delete-button {
  color: #aaa;
}
.table .delete-button:hover {
  color: rgb(143, 3, 3);
}
.order-4, .caret-4, .caret-4-asc, .caret-4-desc {
  margin-right:5px;
  margin-left:5px;
}
.container th.expand-cell-header, 
.container td.expand-cell {
  padding-right: 30px;
  cursor:pointer;
}
.container table.expand-table td {
  cursor: pointer;
}
.container table.expand-table div {
  cursor: auto;
}
.validation-list {
  list-style-type: none;
  padding-left: 15px; 
  margin-top:20px;
  margin-bottom:20px;
}
.validation-list li svg {
  list-style-type: none;
  margin-right: 15px; 
}
#nav-left a:visited,
#nav-left a:link {
  color: black;
  font-weight:bold;
  display:block;
  padding:2px;
  padding-left:10px;
}
#nav-left a:hover
 {
  color: white;
  background-color: black; 
  font-weight:bold;
  text-decoration: none ;
  

}
.container #nav-left .active,
.container #nav-left .active:hover
 {
  color: white;
  background-color: black; 
  font-weight:bold;
  text-decoration: none ;
}
.container #nav-left .inactive:hover {
  color: black;
  background-color: rgba(0, 0, 0, 0.05) 
}
.container .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: black;
  color: white;
}
.container .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover {
  background-color: black;
  color: white;
}
.container .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: black;
  color: white;
}
.container .record-inputs {
  word-wrap: normal;
  margin-top:5px;
  margin-bottom:15px;
}
.container .card-table {
  word-wrap: normal;
}
.container .card-header {
  background-color: #e9ecef;
}

.container .table th, 
.container .table td {
  border:none; 

}
.container .text-filter {
  width:90%;
}
.container .table thead th {
  margin-bottom: 10px;
  padding-bottom: 12px;

}
.container .name-notation-switch {
  margin-top: 20px;
  float: right;
}
#root .navi-alert {
  margin-top:0;
  z-index: 100;
  position: fixed;
  display:flex;
  top:3px;
  margin-left:260px;
  background-color: white;
  border: 1px solid gray;
}
#root .navi-alert svg{
  margin-top:5px;
  margin-right:10px;
  
}
#root .filter {
  font-weight: bold;
}
.userDeleteCheck {
  overflow-y: scroll;
  max-height: 300px;
  margin-bottom:20px;
}
.apikey {
  margin-top:10px;
  margin-bottom: 0;
  font-weight: bold; 
  font-size:14px; 
}
.apikey-info{  
  margin-top: 0;
  margin-bottom: 10px;
  font-size:14px; 
  font-weight: normal;
}